
import LsEditor from '@/components/editor.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiGameAppDetail, apiGameAppAdd, apiGameAppEdit, apiGameAppTypeLists,apiGameUiLists } from '@/api/shop'
import { debounce } from '@/utils/util'

@Component({
  components: {
    MaterialSelect,
    LsEditor
  }
})
export default class GameAppEdit extends Vue {
  /** S Data **/

  identity: number | null = null; // 当前编辑用户的身份ID

  uiList: Array<object> = []

  setGameAppTypeData = []

  // 添加游戏应用表单数据
  form: any = {
    sid: '', // 商户平台id
    name: '', // 名称
    logo: '', // APP Logo
    startup_image: '', // APP启动图
    official_show_pic: '', // APP官网展示图
    status: 1, // 游戏应用状态 0-停止服务 1-开启服务
    domain_alias: '', // 域名别名
    is_check_domain: 1, // 是否验证域名
    sort: 1, // 排序
    game_mobile_url: '', // 当前域名
    game_type: '', // 游戏类型
    remark: '', // 备注
    shop_data: {},
    ali_login_type: 1,
    wx_h5_login_type: 1,
    ali_transfer_type: 0,
    user_post_to_game: 0,
    store_password: '',
    key_password: '',
    key_alias: '',
    agent_ratio: 0,
    mode: 1,
    copyright: '',
    day_count: 2,
    ui_id: '',
    store_file: '/opt/keystore',
    shortplay_sdk: '/opt/shortplaysdk',
    company_name: '',
    set_platform_agent: 0,
    beian_no: ''
  };

  // 表单校验
  rules = {
    name: [{ required: true, message: '请输入游戏应用名称', trigger: 'blur' }],
    logo: [{ required: true, message: '请输入上传APP Logo', trigger: 'change' }],
    //game_type: [{ required: true, message: '请选择游戏类型', trigger: ['blur', 'change'] }],
    startup_image: [{ required: true, message: '请输入上传APP启动图', trigger: 'change' }],
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit (formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return

      if (!this.identity) {
        this.handleShopAdd()
      } else {
        this.handleShopEdit()
      }
    })
  }

  // 初始化文章分类数据
  initUiLists() {
    apiGameUiLists({}).then(res => {
      this.uiList = res.lists
    })
  }

  // 添加游戏应用
  async handleShopAdd (): Promise<void> {
    await apiGameAppAdd({ ...this.form, sid: this.$route.query.sid })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑游戏应用
  async handleShopEdit (): Promise<void> {
    await apiGameAppEdit({ ...this.form, id: this.identity })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 获取详情
  async getGameAppDetailFunc (): Promise<void> {
    const res: any = await apiGameAppDetail({
      id: this.identity as number
    })
    Object.keys(res).map((key) => {
      this.$set(this.form, key, res[key])
    })
  }

  /** E Methods **/

  // 获取套餐列表
  async getGameAppTypeLists (): Promise<void> {
    let result: any  = await apiGameAppTypeLists([])
    console.log("result", result);
    // @ts-ignore
    this.setGameAppTypeData = Object.keys(result).map(e => {
      const obj: any = {
        label: "",
        value: "",
      };
      obj["label"] = result[e];
      obj["value"] = Number(e);
      return obj;
    })
    // this.setGameAppTypeData = result
  }

  /** S Life Cycle **/
  created () {
    const query: any = this.$route.query

    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
      this.getGameAppDetailFunc()
    }

    this.onSubmit = debounce(this.onSubmit, 2000)

    this.initUiLists()
   // this.getGameAppTypeLists()
  }
  /** E Life Cycle **/
}
